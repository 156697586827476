import { Seo } from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components";
import {
  TypographyBlock,
  PageGrid,
  PageGridItem,
  PageSection,
  Button,
  InlineList,
} from "@wsui/base";
import React from "react";

export default function NotFoundPage() {
  return (
    <article>
      <Seo title="Vi kan inte hitta den här sidan" />
      <PageSection>
        <PageGrid>
          <PageGridItem colspan={7}>
            <TypographyBlock marginAfter>
              <h1>Vi kan inte hitta den här sidan</h1>

              <p>
                Sidan du söker verkar inte finnas eller har blivit flyttad.
                Kontrollera att du skrivit rätt och försök gärna igen eller sök
                i fältet längst upp på sidan{" "}
              </p>
            </TypographyBlock>
            <InlineList>
              <Button variant="primary" url="/">
                Gå till startsidan
              </Button>
              <Button variant="secondary" url="/kontakta-oss/">
                Kontakta oss
              </Button>
            </InlineList>
          </PageGridItem>
        </PageGrid>
      </PageSection>
    </article>
  );
}
